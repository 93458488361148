import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Helmet from 'react-helmet';
import Features from '../components/Features';
import PageTitle from '../components/PageTitle';
import Cta from '../components/Cta';

export default ({ data }) => {
  const { frontmatter } = data.pageMarkdown
  const { frontmatter: cta } = data.ctaMarkdown
  const { siteMetadata } = data.site

  return (
    <Layout color="4">
      <Helmet title={`${frontmatter.title} | ${siteMetadata.title}`}>
        <meta name="description" content={ frontmatter.description } />
      </Helmet>
      <PageTitle metadata={siteMetadata}>
        <h1>{frontmatter.title}</h1>
      </PageTitle>
      <Features data={frontmatter.feature_groups} />
      <Cta
        title={ cta.title }
        buttonLink={ cta.button.link }
        buttonText={ cta.button.text }
      />
    </Layout>
  )
}

export const featuresPageQuery = graphql`
  query FeaturesPage($path: String!) {
    pageMarkdown: markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        title
        description
        feature_groups {
          img {
            image_file
            alt_text
          }
          group_title
          items {
            text
          }
        }
      }
    }
    ...cta
    ...siteTitle
  }
`
