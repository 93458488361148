import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './Features.scss';

const Features = ({ data }) => (
  <section id="main-content" className="Features">
    <div className="Features__wrapper">
      <Tabs className="Features__desktop">
        <TabList className="Features__tabs">
        { data.map((group, index) => (
          <Tab
            key={ group.group_title }
            className="Features__tab"
            tabIndex="0"
          >
              <figure className="Features__tab-figure">
                <img src={ group.img.image_file } alt={ group.img.alt_text } className="Features__tab-image" />
                <figcaption className="Features__tab-title">
                  { group.group_title }
                </figcaption>
              </figure>
            </Tab>
          ))}
        </TabList>
        { data.map((group, index) => (
        <TabPanel key={ group.group_title } id={ 'tab-' + index } className="feature-group feature-group__desktop">
          <h2 className="feature-group__title">{ group.group_title }</h2>
          <div className="feature-group__items">
            { group.items.map(item => (
              <div key={ item.text } className="feature-group__item">
                { item.text}
              </div>
            ))}
          </div>
        </TabPanel>
        ))}
      </Tabs>
      <div className="Features__mobile">
        { data.map((group, index) => (
        <section key={ group.group_title + ' Mobile' } className="feature-group feature-group__mobile">
          <div className="feature-group__header">
            <img src={ group.img.image_file } alt={ group.img.alt_text } className="feature-group__image" />
            <h2 className="feature-group__title">{ group.group_title }</h2>
          </div>
          <div className="feature-group__items">
            { group.items.map(item => (
              <div key={ `${ item.text } Mobile` } className="feature-group__item">
                { item.text}
              </div>
            ))}
          </div>
        </section>
        ))}
      </div>
    </div>
  </section>
)

export default Features;
